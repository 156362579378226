<template>
  <div>
    <KTCodePreview v-bind:title="'In phiếu'">
      <template v-slot:toolbar>
        <b-button
          variant="primary"
          size="sm"
          @click="printData"
          style="width: max-content"
        >
          <i class="fas fa-print"></i>
          In phiếu
        </b-button>
      </template>
      <template v-slot:preview>
        <div id="printMe">
          <b-row style="display: block" class="mb-10">
            <div class="text-center">
              <h5>PHIẾU CHUYỂN KHO</h5>
            </div>
          </b-row>
          <b-row>
            <b-col md="8">
              <p>
                <span class="custom-lable-text">Mã phiếu</span>
                : {{ stockSlip.code }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <p>
                <span class="custom-lable-text">Ngày</span>
                : {{ stockSlip.createdAt }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <p>
                <span class="custom-lable-text">Kho chuyển</span>
                : {{ stockSlip.sourceStoreName }}
              </p>
            </b-col>
            <b-col md="4">
              <p>
                <span class="custom-lable-text">Kho nhận</span>
                : {{ stockSlip.destinationStoreName }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <p>
                <span class="custom-lable-text">Địa chỉ</span>
                : {{ stockSlip.sourceStoreAddress }}
              </p>
            </b-col>
            <b-col md="4">
              <p>
                <span class="custom-lable-text">Địa chỉ</span>
                : {{ stockSlip.destinationStoreAddress }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <p>
                <span class="custom-lable-text">Ghi chú</span>
                : {{ stockSlip.description }}
              </p>
            </b-col>
            <b-col md="4">
              <p>
                <span class="custom-lable-text">Người lập</span>
                : {{ stockSlip.createBy }}
              </p>
            </b-col>
          </b-row>
          <b-table
            :items="listItem"
            :fields="fields"
            :bordered="true"
            :hover="true"
          >
            <template slot="bottom-row">
              <td
                v-bind:colspan="5"
                class="text-right font-weight-bolder"
                style="font-weight: 600,color: '#181c32'"
              >
                <span>Tổng:</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span>{{ formatMoney(sumQuantity) }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span v-if="stockSlip.status > STOCK_SLIP_STATUS.NEW">{{
                  formatMoney(sumApproveQuantity)
                }}</span>
              </td>
              <td class="text-right font-weight-bolder">
                <span v-if="stockSlip.status === STOCK_SLIP_STATUS.CONFIRMED">{{
                  formatMoney(sumConfirmQuantity)
                }}</span>
              </td>
            </template>
          </b-table>
          <b-row>
            <b-col class="text-center">
              <p class="mb-0">
                <span class="custom-lable-text">Người lập phiếu</span>
              </p>
              <p>(Ký, họ tên)</p>
            </b-col>
            <b-col>
              <p class="mb-0">
                <span class="custom-lable-text">Người giao hàng</span>
              </p>
              <p>(Ký, họ tên)</p>
            </b-col>
            <b-col>
              <p class="mb-0">
                <span class="custom-lable-text">Người nhận hàng</span>
              </p>
              <p>(Ký, họ tên)</p>
            </b-col>
            <b-col
              ><p class="mb-0">
                <span class="custom-lable-text">Thủ kho</span>
              </p>
              <p>(Ký, họ tên)</p>
            </b-col>
            <b-col
              ><p class="mb-0">
                <span class="custom-lable-text">Giám đốc</span>
              </p>
              <p>(Ký, họ tên)</p>
            </b-col>
          </b-row>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import _ from 'lodash';
import { printData } from '../../../utils/common';
import { STOCK_SLIP_STATUS } from '../../../utils/enum';

export default {
  data() {
    return {
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'barCode',
          label: 'Mã vạch',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'imeiCode',
          label: 'IMEI',
          thStyle: { fontWeight: 600, color: '#181c32', width: '17%' },
        },
        {
          key: 'quantity',
          label: 'SL yêu cầu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '9%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return this.formatMoney(value);
          },
        },
        {
          key: 'approveQuantity',
          label: 'SL duyệt',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '9%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return this.stockSlip.status > STOCK_SLIP_STATUS.NEW
              ? this.formatMoney(value)
              : '';
          },
        },
        {
          key: 'confirmQuantity',
          label: 'SL xác nhận',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '9%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return this.stockSlip.status > STOCK_SLIP_STATUS.APPROVED
              ? this.formatMoney(value)
              : '';
          },
        },
      ],
      listItem: [],
      stockSlip: {
        code: '',
        sourceStoreName: '',
        destinationStoreName: '',
        createdAt: '',
        sourceStoreAddress: '',
        destinationStoreAddress: '',
        createBy: '',
        description: '',
        status: 1,
      },
      sumQuantity: 0,
      sumApproveQuantity: 0,
      sumConfirmQuantity: 0,
      STOCK_SLIP_STATUS: STOCK_SLIP_STATUS,
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'In phiếu kho chuyển kho' },
    ]);
    this.getStockSlipById();
  },
  methods: {
    printData() {
      printData();
    },
    formatMoney: function (number) {
      if (!number) return 0;
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    getStockSlipById: async function () {
      const stockSlipId = this.$route.query.id;
      await ApiService.get(
        `stock-slips/print-transfer-stockslips/${stockSlipId}`
      ).then((data) => {
        const responseData = data.data.data;
        responseData.stockSlipDetails.map((element, index) => {
          const item = {
            count: index + 1,
            ...element,
          };
          this.listItem.push(item);
        });
        this.stockSlip = responseData;
        this.sumQuantity = _.sumBy(this.listItem, 'quantity');
        this.sumApproveQuantity = _.sumBy(this.listItem, 'approveQuantity');
        this.sumConfirmQuantity = _.sumBy(this.listItem, 'confirmQuantity');
      });
      printData();
    },
  },
};
</script>

<style scoped>
.custom-lable-text {
  font-size: 13px;
  font-weight: 500 !important;
}
</style>

<style >
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
